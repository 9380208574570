import { Icons } from 'helpers/icons'
import { Trans } from 'next-i18next'

const FOOTER_SOCIALS = [
  {
    name: 'Twitter',
    href: 'https://x.com/YellowCom_News',
    icon: () => <Icons name="twitter" />,
    target: '_blank',
  },
  {
    name: 'Telegram',
    href: 'https://t.me/YellowCom_news',
    icon: () => <Icons name="telegram" />,
    target: '_blank',
  },
  {
    name: 'Youtube',
    href: 'https://www.youtube.com/channel/UC2u2FXKKyIFsNBr_MlpCMfA/',
    icon: () => <Icons name="youtube" />,
    target: '_blank',
  },
  {
    name: 'Linkedin',
    href: 'https://www.linkedin.com/company/yellow-com/',
    icon: () => <Icons name="linkedin" />,
    target: '_blank',
  },
  {
    name: 'Discord',
    href: 'https://discord.com/invite/DKBjCg6rmT',
    icon: () => <Icons name="discord" />,
    target: '_blank',
  },
  {
    name: 'Medium',
    href: 'https://medium.com/yellow-blog',
    icon: () => <Icons name="medium" />,
    target: '_blank',
  },
  {
    name: 'Facebook',
    href: 'https://www.facebook.com/YellowComNews',
    icon: () => <Icons name="facebook" />,
    target: '_blank',
  },
  {
    name: 'Reddit',
    href: 'https://www.reddit.com/r/YellowDeFi/',
    icon: () => <Icons name="reddit" />,
    target: '_blank',
  },
]

const FOOTER_NAVIGATION = [
  {
    name: <Trans i18nKey="footer.navigation.yellow" />,
    submenu: [
      {
        name: <Trans i18nKey="footer.navigation.yellow.submenu.about" />,
        href: '/about_us',
        target: '_blank',
      },
      // {
      //   name: <Trans i18nKey="footer.navigation.yellow.submenu.team" />,
      //   href: '/',
      //   target: '_blank',
      // },
      {
        name: <Trans i18nKey="footer.navigation.yellow.submenu.contact" />,
        href: '/',
        target: '_blank',
      },
      {
        name: <Trans i18nKey="footer.navigation.yellow.submenu.openPositions" />,
        href: 'https://yellow.com/news/yellowcom-is-looking-for-a-content-editor',
        target: '_blank',
      },
      {
        name: <Trans i18nKey="footer.navigation.yellow.submenu.writeForUs" />,
        href: '/',
        target: '_blank',
      },
      {
        name: <Trans i18nKey="footer.navigation.yellow.submenu.complains" />,
        href: '/',
        target: '_blank',
      },
    ],
  },
  {
    name: <Trans i18nKey="footer.navigation.legal" />,
    submenu: [
      {
        name: <Trans i18nKey="footer.navigation.legal.submenu.terms" />,
        href: '/terms_of_service',
        target: '_blank',
      },
      {
        name: <Trans i18nKey="footer.navigation.legal.submenu.privacy" />,
        href: '/privacy_policy',
        target: '_blank',
      },
      {
        name: <Trans i18nKey="footer.navigation.legal.submenu.cookie" />,
        href: '/cookie_policy',
        target: '_blank',
      },
    ],
  },
  {
    name: <Trans i18nKey="footer.navigation.company" />,
    submenu: [
      {
        name: <Trans i18nKey="footer.navigation.company.submenu.yellowNetwork" />,
        href: 'https://www.yellow.org/',
        target: '_blank',
      },
      {
        name: <Trans i18nKey="footer.navigation.company.submenu.yellowCapital" />,
        href: 'https://www.yellowcapital.com/',
        target: '_blank',
      },
      {
        name: <Trans i18nKey="footer.navigation.company.submenu.openware" />,
        href: 'https://www.openware.com/',
        target: '_blank',
      },
      {
        name: <Trans i18nKey="footer.navigation.company.submenu.yellowCoworking" />,
        href: 'https://www.yellowincubator.com/coworking',
        target: '_blank',
      },
      {
        name: <Trans i18nKey="footer.navigation.company.submenu.foundation" />,
        href: 'https://www.yellowincubator.com/foundation',
        target: '_blank',
      },
    ],
  },
  {
    name: <Trans i18nKey="footer.navigation.exchange" />,
    submenu: [
      {
        name: <Trans i18nKey="footer.navigation.exchange.submenu.howItWorks" />,
        href: 'https://docs.yellow.org/about/the-solution',
        target: '_blank',
      },
      {
        name: <Trans i18nKey="footer.navigation.exchange.submenu.tokens" />,
        href: '/join_yellow_network',
        target: '_blank',
      },
      {
        name: <Trans i18nKey="footer.navigation.exchange.submenu.yellowToken" />,
        href: '/discover_yellow_token',
        target: '_blank',
      },
    ],
  },
]

export const FOOTER_OPTIONS = {
  navigations: FOOTER_NAVIGATION,
  socials: FOOTER_SOCIALS,
  upIcon: () => <Icons name="upIcon" />,
  downIcon: () => <Icons name="downIcon" />,
  socialIconNumDisplay: 8,
}
