import { Logo, darkModeLogo, headerDarkModeIcon, headerLogoIcon } from 'assets/images/Logo'
import {
  navigation,
  navigationAuthorizedYellow,
  navigationLoggedin,
  navigationMobile,
  navigationYellow,
} from 'configs/navigation'
// import { useAnalyticsInit } from 'hooks/useAnalyticsInit'
import { useRouter } from 'next/router'
import React, { PropsWithChildren, useCallback, useEffect, useMemo, useState } from 'react'
import { useCookies } from 'react-cookie'
import { CustomFooter } from './CustomFooter'
import { CustomHeader } from './CustomHeader'
import { Layout as SharedLayout, isBrowser, SidebarProps, navigationApp, navigationAppItem, HeaderProps, useAppSelector, RootState } from 'web-sdk'
import { useTranslation } from 'next-i18next'

interface HeaderNavigations {
  name: string | React.ReactNode
  href: string
}

interface HeaderOptions {
  navigations: HeaderNavigations[]
  customHeaderContent?: React.ReactNode
  responseIsMobile?: boolean
}

interface LayoutProps {
  className?: string
  headerOptions?: HeaderOptions
  hideFooter?: boolean
  hideHeader?: boolean
  hideOptionsMenu?: boolean
  actionButtonProps?: {
    text: JSX.Element | string
    link: string
  }
}

// const currentMarketSelector = (state: RootState) => state.markets.currentMarket
// const marketsSelector = (state: RootState) => state.markets.markets
const userSelector = (state: RootState) => state.user
const themeSelector = (state: RootState) => state.globalSettings.color

export function AppLayout({
  className,
  headerOptions,
  hideFooter,
  hideHeader,
  hideOptionsMenu = false,
  actionButtonProps,
  children,
}: PropsWithChildren<LayoutProps>): JSX.Element {
  // const markets = useAppSelector(marketsSelector)
  const { userLoading } = useAppSelector(userSelector)
  // const currentMarket = useAppSelector(currentMarketSelector)
  const themeSwitcher: string = useAppSelector(themeSelector)
  // const isSetupMode: boolean = useAppSelector(state => state.globalSettings.isSetupMode)
  // const isLoginModalOpen = useAppSelector(state => state.globalSettings.isLoginModalOpen)

  // const userRole = user.role
  // const isAdminWriter = ['superadmin', 'writer'].includes(userRole)
  const router = useRouter()
  const { t } = useTranslation('common')
  // const dispatch = useAppDispatch()

  useEffect(() => {
    setTimeout(function () {
      window.scrollTo(0, 0)
    }, 1)
  }, [router.asPath])

  const [headerLogo, setHeaderlogo] = useState<any>()
  const [sideBarLogo, setSideBarLogo] = useState<any>()

  const [cookies, setCookie] = useCookies(['role'])

  // const isLoggedin = useMemo(() => user?.id, [user?.id])
  // const isAdmin = useMemo(() => ['admin', 'superadmin'].includes(user?.role), [user?.role])

  // temporary fixed user and role state
  const isLoggedin = false
  const isAdmin = false
  const userRole = 'superadmin'
  const isAdminWriter = false

  const isReloadOnRoleChanged = useMemo(
    () => ['/articles/[...slug]', '/news/[page]'].includes(router.pathname),
    [router.pathname],
  )

  // useAnalyticsInit(user)

  const changeLogos = useCallback(() => {
    if (themeSwitcher === 'dark') {
      setHeaderlogo(headerDarkModeIcon)
      setSideBarLogo(darkModeLogo)
    } else {
      setHeaderlogo(headerLogoIcon)
      setSideBarLogo(Logo)
    }
  }, [themeSwitcher])

  useEffect(() => {
    window.addEventListener('load', changeLogos)
    return () => {
      window.removeEventListener('load', changeLogos)
    }
  }, [changeLogos])

  useEffect(() => {
    changeLogos()
  }, [themeSwitcher])

  useEffect(() => {
    if (userLoading) {
      return
    }

    if (cookies.role !== userRole) {
      setCookie('role', userRole, { path: '/' })
      isReloadOnRoleChanged && router.reload()
    }
  }, [cookies.role, userRole, userLoading])

  useEffect(() => {
    if (isBrowser()) {
      const element = document.querySelector('a[aria-label="Trade"]')
      const elementWallet = document.querySelector('a[aria-label="Wallet"]')

      // Check if the element exists to avoid errors
      if (element && elementWallet) {
        // Update the class of the selected element
        element.className =
          'no-underline group flex items-center mt-1 px-2 py-2 text-xs font-metro-semibold rounded-md text-neutral-control-layer-color-60 hover:bg-navbar-control-bg-color-10 flex-col'

        elementWallet.className =
          'no-underline group flex items-center mt-1 px-2 py-2 text-xs font-metro-semibold rounded-md text-neutral-control-layer-color-60 hover:bg-navbar-control-bg-color-10 flex-col'
      }
    }
  }, [isBrowser()])

  const navigations = useMemo((): navigationApp[] => {
    const mainNavigation = isLoggedin ? navigationLoggedin : navigation
    const mainYellowNavigation =
      userRole === 'superadmin' || userRole === 'writer' ? navigationAuthorizedYellow : navigationYellow

    return [
      {
        app: t('page.sidebar.navigation.separator.list'),
        pathnames: mainYellowNavigation.map<navigationAppItem>((nav: navigationAppItem) => {
          return {
            ...nav,
            name: t(`page.sidebar.navigation.${nav.name.toLowerCase()}`),
            // path:
            //   nav.path === '/trading' && markets?.length
            //     ? currentMarket
            //       ? `${nav.path}/${currentMarket.id}`
            //       : `${nav.path}/${markets[0].id}`
            //     : nav.path,
            path: nav.path,
            skipUseCurrentPath: true,
          }
        }),
      },
      {
        app: t('page.sidebar.navigation.separator.trade'),
        pathnames: mainNavigation.filter((nav: navigationAppItem) => !nav.bottom).map<navigationAppItem>((nav: navigationAppItem) => {
          if (nav.submenus?.length) {
            return {
              ...nav,
              name: t(`page.sidebar.navigation.${nav.name.toLowerCase()}`),
              submenus: nav.submenus.map((submenu: any) => {
                return {
                  ...submenu,
                  name: t(`page.sidebar.navigation.${nav.name.toLowerCase()}.submenu.${submenu.name.toLowerCase()}`),
                }
              }),
              skipUseCurrentPath: true,
            }
          }

          return {
            ...nav,
            name: t(`page.sidebar.navigation.${nav.name.toLowerCase()}`),
            // path:
            //   nav.path === '/trading' && markets?.length
            //     ? currentMarket
            //       ? `${nav.path}/${currentMarket.id}`
            //       : `${nav.path}/${markets[0].id}`
            //     : nav.path,
            path: nav.path,
            skipUseCurrentPath: true,
          }
        }),
      },
      {
        app: 'settings',
        pathnames: mainNavigation.filter((nav: navigationAppItem) => nav.bottom).map<navigationAppItem>((nav: navigationAppItem) => {
          return {
            ...nav,
            name: t(`page.sidebar.navigation.${nav.name.toLowerCase()}`),
            path: nav.path,
            skipUseCurrentPath: true,
          }
        }),
        bottom: true,
      },
    ]
  }, [isLoggedin, userRole])

  const mobileNavigation = useMemo((): navigationApp[] => {
    if (!navigationMobile) return []

    return [
      {
        app: 'mainapp',
        pathnames: navigationMobile.map<navigationAppItem>((nav: navigationAppItem) => {
          if (nav.submenus?.length) {
            return {
              ...nav,
              name: t(`page.sidebar.navigation.${nav.name.toLowerCase()}`),
              submenus: nav.submenus.map((submenu: any) => {
                return {
                  ...submenu,
                  name: t(`page.sidebar.navigation.${nav.name.toLowerCase()}.submenu.${submenu.name.toLowerCase()}`),
                }
              }),
            }
          }

          return {
            ...nav,
            name: t(`page.sidebar.navigation.${nav.name.toLowerCase()}`),
            // path:
            //   nav.path === '/trading' && markets?.length
            //     ? currentMarket
            //       ? `${nav.path}/${currentMarket.id}`
            //       : `${nav.path}/${markets[0].id}`
            //     : nav.path,
            path: nav.path,
          }
        }),
      },
    ]
  }, [navigationMobile])

  const adminNavigation = useMemo((): navigationApp[] => {
    if (!isAdmin) return []
    return [
      {
        app: 'mainapp',
        pathnames: [].map<navigationAppItem>((nav: navigationAppItem) => {
          if (nav.submenus?.length) {
            return {
              ...nav,
              name: t(`page.sidebar.navigation.${nav.name.toLowerCase()}`),
              submenus: nav.submenus.map((submenu: any) => {
                return {
                  ...submenu,
                  name: t(`page.sidebar.navigation.${nav.name.toLowerCase()}.submenu.${submenu.name.toLowerCase()}`),
                }
              }),
              skipUseCurrentPath: true,
            }
          }

          return {
            ...nav,
            name: t(`page.sidebar.navigation.${nav.name.toLowerCase()}`),
            path: nav.path,
            skipUseCurrentPath: true,
          }
        }),
      },
    ]
  }, [isAdmin])

  const buttonsList = useMemo(() => {
    // if (isBrowser() && window.localStorage.getItem('show_auth_btn') === 'true') {
    //   return isLoggedin
    //     ? [
    //         {
    //           name: 'Metamask',
    //           component: (
    //             <LoginButtonWidget buttonClassNames="inline-flex w-full justify-center px-4 py-2 border border-transparent text-sm font-metro-medium rounded-md shadow-sm text-primary-cta-layer-color-60 duration-200 bg-primary-cta-color-60 hover:bg-primary-cta-color-80 active:bg-primary-cta-color-90" />
    //           ),
    //           label: t('page.sidebar.bottom.account'),
    //         },
    //       ]
    //     : [
    //         {
    //           name: 'Metamask',
    //           component: (
    //             <LoginButtonWidget buttonClassNames="flex justify-center items-center h-10 cursor-pointer rounded-md border border-transparent text-primary-cta-layer-color-60 duration-200 bg-primary-cta-color-60 hover:bg-primary-cta-color-80  active:bg-primary-cta-color-90" />
    //           ),
    //           label: t('page.sidebar.bottom.account'),
    //         },
    //       ]
    // } else {
    //   return []
    // }

    return []
  }, [isLoggedin, isBrowser()])

  const adminButtonsList = useMemo(() => {
    if (!isAdmin) return []
    return [
      {
        name: 'admin',
        label: t('page.sidebar.navigation.admin'),
        path: '/admin/project/default',
        newTab: true,
      },
      {
        name: 'user',
        label: t('page.sidebar.navigation.user'),
        path: '/trading',
      },
    ]
  }, [isAdmin])

  const sidebarProps: SidebarProps = {
    currentApp: 'mainapp',
    navigations,
    mobileNavigation,
    adminNavigation,
    adminButtonsList,
    showAdminNavigation: isAdmin,
    classNames: 'bg-navbar-background-color sm:border-r border-divider-color-20 fixed z-[30] with-scrollbar',
    bottomClasses:
      'fixed w-screen bottom-0 z-30 flex-shrink-0 md:hidden flex h-16 bg-navbar-background-color border-t border-divider-color-20 w-full left-0',
    navActiveClassNames: 'bg-navbar-control-bg-color-10 text-navbar-control-layer-color-60',
    navInactiveClassNames: 'text-neutral-control-layer-color-60 hover:bg-navbar-control-bg-color-10',
    navOverlayClasses: 'relative bg-navbar-background-color flex-1 flex flex-col max-w-[260px] pt-5 pb-4',
    navOverlayClassNames:
      'relative no-underline duration-150 group flex items-center mt-1 px-2 py-2 text-md font-metro-bold rounded-md',
    navMinimizedClassNames:
      'no-underline group flex items-center mt-1 px-2 py-2 text-xs font-metro-semibold rounded-md',
    isLoggedin: false,
    buttonsList,
    logo: sideBarLogo,
    linkOnLogo: '/',
    showMobileSidebarToggler: true,
    mobileNavbarClasses: 'text-xs font-metro-semibold text-neutral-control-layer-color-60 mx-2.5 my-1 rounded',
    mobileNavbarActiveClasses: 'bg-navbar-control-bg-color-10',
    mobileSpanActiveColor: 'text-neutral-control-layer-color-100',
    showNavGroupTitles: true,
    navMoreLabel: t('page.sidebar.navigation.more'),
    exceptedDefaultActivePathnames: ['/404', '/trading', '/assets', '/news', '/articles', '/settings', '/balances'],
  }

  // const renderLoginModal = () => {
  //   if (isBrowser() && window.localStorage.getItem('show_auth_btn') === 'true') {
  //     return (
  //       <LoginModal
  //         open={isLoginModalOpen}
  //         setOpen={() => dispatch(toggleLoginModalOpen())}
  //         authProvidersButtonDisabledClassName={`mb-3 min-w-full h-9 inline-flex justify-center rounded-md border border-gray-300 shadow-sm px-4 py-1.5 bg-gray-300 text-base font-metro-medium ${
  //           themeSwitcher === 'dark' ? 'fill-neutral-control-layer-color-50' : 'text-gray-400'
  //         } focus:outline-none focus:ring-none sm:mt-0 sm:w-auto sm:text-sm duration-200`}
  //         authProvidersButtonActiveClassName={`mb-3 min-w-full h-9 inline-flex justify-center rounded-md border border-gray-300 shadow-sm px-4 py-1.5 bg-white text-base font-metro-medium ${
  //           themeSwitcher === 'dark' ? 'fill-neutral-control-layer-color-10' : 'text-gray-700'
  //         } hover:text-gray-500 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500 sm:mt-0 sm:w-auto sm:text-sm duration-200`}
  //         authProvidersButtonLabel={(providerName: string, providerIcon: JSX.Element, isButtonEnabled: boolean) => (
  //           <div className="flex flex-row justify-center items-center">
  //             {providerName === 'Google' && themeSwitcher === 'dark' ? (
  //               <GoogleIcon isButtonEnabled={isButtonEnabled} />
  //             ) : (
  //               providerIcon
  //             )}
  //             <div className="h-5 w-max -mt-0.5 ml-1.5">{providerName}</div>
  //           </div>
  //         )}
  //         termsLink="/terms_of_service"
  //       />
  //     )
  //   }

  //   return null
  // }

  const customHeaderProps = () => {
    const headerProps: HeaderProps = {
      options: headerOptions,
      headerLogoIcon: headerLogo,
    }

    if (headerOptions) {
      return headerProps
    }
    return {}
  }

  const computedActionButtonProps = () => {
    const defaultActionButtonProps = {
      text: t('header.button.vault'),
      link: 'https://vault.yellow.com',
    }

    if (isAdminWriter && actionButtonProps) {
      return actionButtonProps
    }

    return defaultActionButtonProps
  }

  const renderLayout = useMemo(() => {
    return (
      <SharedLayout
        containerClassName={className}
        sidebarProps={sidebarProps}
        customHeader={ hideHeader ? <></> : 
          <CustomHeader
            actionButtonProps={computedActionButtonProps()}
            hideOptionsMenu={hideOptionsMenu}
            {...customHeaderProps()}
          />
        }
        customFooter={hideFooter ? null : <CustomFooter />}
        mainClassName="flex-1 flex flex-col relative overflow-y-auto overflow-x-hidden focus:outline-none md:ml-20"
      >
        {children}
        {/* <MarketSelectorWidget /> */}
        {/* <WalletConnectedState /> */}
        {/* {renderLoginModal()} */}
      </SharedLayout>
    )
  }, [hideHeader, headerLogo, sidebarProps])

  return renderLayout
}

export const Layout = React.memo(AppLayout)
